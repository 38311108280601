import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '@/views/Home'

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        redirect: "",
        component: () => import('@/views/Home'),
        meta: {
            title: '和裕网络'
        }
    },
    // {
    //     path: '/',
    //     redirect: "/Introduce",
    //     component: () => import('@/views/introduce/introduce')
    // },
    {
        path: '/Home',
        name: "Home",
        component: () => import('@/views/Home'),
        meta: {
            title: '和裕网络'
        }
    },
    {
        path: '/Album',
        name: "Album",
        component: () => import('@/views/album/album'),
        meta: {
            title: '专辑单曲'
        },
    },
    {
        path: '/ArtistIntro',
        name: "ArtistIntro",
        component: () => import('@/views/artistIntro/artistIntro'),
        meta: {
            title: '艺人介绍'
        },
    },
    {
        path: '/IpCapacity',
        name: "IpCapacity",
        component: () => import('@/views/ipCapacity/ipCapacity'),
        meta: {
            title: 'IP合作'
        },
    },
    {
        path: '/NumberCardHb',
        name: "NumberCardHb",
        component: () => import('@/views/numberCardHb/numberCardHb'),
        meta: {
            title: '号卡权益'
        },
    },
    {
        path: '/AiCapacity',
        name: "AiCapacity",
        component: () => import('@/views/aiCapacity/aiCapacity'),
        meta: {
            title: 'AI能力'
        },
    },
    {
        path: '/Apply',
        name: "Apply",
        component: () => import('@/views/apply/apply'),
        meta: {
            title: '达人入驻'
        },
    },
    {
        path: '/Introduce',
        name: "Introduce",
        component: () => import('@/views/introduce/introduce'),
        meta: {
            title: '公司介绍'
        },
    },
    {
        path: '/MusicPlay',
        name: "MusicPlay",
        component: () => import('@/views/musicPlay/musicPlay'),
        meta: {
            title: '音乐播放'
        },
    },
    {
        path: '/ArtistIntroMore',
        name: "ArtistIntroMore",
        component: () => import('@/views/artistIntroMore/artistIntroMore'),
        meta: {
            title: '详细介绍'
        },
    },
    {
        path: '/System',
        name: "System",
        component: () => import('@/views/system/system'),
        meta: {
            title: '详细介绍'
        },
    }
]

const router = new VueRouter({
    routes
})
router.beforeEach(async (to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next()
})

export default router
import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from "@/router";
import VueAwesomeSwiper from 'vue-awesome-swiper';
import '../node_modules/swiper/swiper-bundle.css'
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
